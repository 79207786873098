<template>
  <div class="detail" v-if="currentRoute">
    <stage stageLabel="Team" :title="currentRoute.title.rendered" :image="currentRoute.acf.image.url"/>
    <div class="detail__content">
      <div ref="content" class="detail__markdown" v-html="currentRoute.content.rendered" />
      <div class="detail__contact">
        <h3 v-if="hasContact">Kontakt</h3>
          <div v-if="hasContact" class="detail__contact-content">
              <div class="detail__contact-content-details">
                    <a :href="`tel:${currentRoute.acf.contact.phone}`">
                        {{currentRoute.acf.contact.phone}}
                    </a>
                    <a :href="`mailto:${currentRoute.acf.contact.email}`">
                        {{currentRoute.acf.contact.email}}
                    </a>
                    <social-icons :icons="currentRoute.acf.contact.social"/>
              </div>
          </div>
      </div>
    </div>
    <tag-posts header="Weitere Informationen" :posts="tagPosts" :route="$route.params.name"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import SocialIcons from '@/components/social-icons.vue'
import TagPosts from '@/components/tag-posts.vue'
import {mapGetters, mapActions} from 'vuex'


export default {
  name: 'detail',
  components: {
    Stage,
    SocialIcons,
    TagPosts,
  },
  computed: {
    ...mapGetters([
        'getFetched',
    ]),
    currentRoute(){
      const getFetched =  this.getFetched[this.$route.params.name];
      return getFetched;
    },
    tagPosts(){
      if (this.currentRoute) {
        return this.currentRoute.postTags ? this.currentRoute.postTags : [];
      }
      return [];
    },
    hasContact() {
      return Object.values(this.currentRoute.acf.contact).some(val => val) || false;
    }
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPost(this.$route.params.name);
          
      }
  },
  watch: {
    currentRoute() {
      this.$nextTick(() => {
        this.checkForText();
      })
    }
  },
  methods: {
    ...mapActions([
          'fetchCurrentPost',
    ]),
    checkForText() {
      const elms = this.$refs.content.children;
      const withHtml = [...elms].filter(e => e.children.length);
      withHtml[0].classList.add('wide')
    }
  }
}
</script>
<style lang="scss">
@import "~@/scss/markdown";
.detail {
  &__markdown {
    @include markdown;
    @media (min-width: 768px) {
      padding: 0 1.6rem 0 0;
    }
    @media (min-width: 1140px) {
      padding: 0 30px 0 100px;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      max-height: 300px;
      margin: 1.6rem 0 0;
      @media (min-width: 1024px) {
        margin: 65px 0 0;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.detail {
  @import "~@/scss/markdown";
  margin-top: 86px;
  padding: 0 .8rem;
  margin-bottom: 1.6rem;
  @media (min-width: 1024px) {
    margin-bottom: 150px;
  }
  &__content {
    max-width: 1140px;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 .8rem;
    @media (min-width: 768px) {
      flex-direction: row;
      padding: 0;
    }
  }
  &__markdown, &__contact {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &__contact {
    max-width: 442px;
    font-size: 18px;
  }
  &__contact-content-details {
    display: flex;
    flex-direction: column;
    a {
      color: #f3972b;
        font-style: italic;
        text-decoration: none;
        font-weight: 700;
        &:hover {
            text-decoration: underline;
        }
    }
  }
}
</style>
